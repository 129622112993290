import { BaseStore } from "./BaseStore";
import { observable, autorun, action, computed, observe, runInAction, when, makeObservable } from "mobx";

import { LockForm } from "../Models";
import { Disposer } from "../Models";
import axios, { AxiosResponse } from "axios";
import { ApiResult } from "../Models/ApiResult";
import { HubConnection, HubConnectionBuilder, HubConnectionState, RetryContext } from "@microsoft/signalr";
import { CoreStoreInstance } from "../Stores/CoreStore";

type PrivateActions =
    | "formLocks"
    | "formRef"
    | "disposers"
    | "connectionQueue"
    | "registeredForms"
    | "cid"
    | "myLockedForms"
    | "signalRAccessToken"
    | "connectedServer"
    | "enabled"
    | "setInitialFormLocks";
export class FormLockConcurrencyHubStore extends BaseStore {
    private signalRConcurrencyHubConnection: HubConnection;

    private formLocks = observable<{
        [ref: string]: { locked: boolean; lockedBy: string; id: string };
    }>({}); // Keep track of who by and if all form types are locked
    private formRef = ""; // The form ref the client is currently tracking
    private disposers: Disposer[] = []; // Disposers to unregister from notifications
    private connectionQueue: (() => void)[] = []; // Queue of callbacks to invoke when the connection is started
    private registeredForms: number[] = []; // Keep track of which forms the client has registered to recieve notification for
    private cid: string = ""; // The current connection id
    private myLockedForms: number[] = []; // The forms the client intents to be locked (to keep track of which forms should be re-claimed if the server goes down)
    private signalRAccessToken: string = "";
    private connectedServer: string = "";
    private enabled: boolean = false;

    constructor() {
        super();

        this.signalRConcurrencyHubConnection = new HubConnectionBuilder()
            .withUrl(`/hubs/concurrency/formlock`, {
                accessTokenFactory: () => this.signalRAccessToken,
            }) // Access token to allow authentication, also allows server to get the username of the client
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (context: RetryContext) => {
                    return 1000;
                },
            }) // Don't stop attempting to reconnect
            .build();
        makeObservable<FormLockConcurrencyHubStore, PrivateActions>(this, {
            formLocks: observable,
            formRef: observable,
            disposers: observable,
            connectionQueue: observable,
            registeredForms: observable,
            cid: observable,
            myLockedForms: observable,
            signalRAccessToken: observable,
            connectedServer: observable,
            enabled: observable,
            enqueueToConnectionQueue: action,
            setInitialFormLocks: action,
            setSignalRAccessToken: action,
            setFormsInfo: action,
            registerForms: action,
            getIsHubConnected: computed,
            getResetFormLocks: computed,
            onLock: action,
            onUnlock: action,
            getFormRef: computed,
            lockForm: action,
            forceUnlockForm: action,
            unlockAllForms: action,
            unlockForm: action,
            connect: action,
            disconnect: action,
        });
    }

    //public init(stores: Stores, initialState: InitialState) {
    public init(siglanRAccessToken: string, enabled: boolean) {
        this.signalRAccessToken = siglanRAccessToken;
        this.enabled = enabled;

        autorun(() => {
            if (CoreStoreInstance.IsLoggedIn && this.signalRAccessToken) {
                this.connect();
            } else {
                if (this.enabled) {
                    axios.post(`/api/account/GetSignalRToken`).then((apiResult: AxiosResponse<ApiResult<string>>) => {
                        if (apiResult.status === 200 && apiResult.data && apiResult.data.wasSuccessful) {
                            this.setSignalRAccessToken(apiResult.data.payload);
                        }
                    });
                } else {
                    this.disconnect();
                }
            }
        });
    }

    public enqueueToConnectionQueue(cb: () => void) {
        this.connectionQueue.push(cb);
    }

    private setInitialFormLocks() {
        this.signalRConcurrencyHubConnection.invoke(`GetAreFormsLockedAsync`, this.registeredForms, this.formRef).then((initalFormLocks: string[]) => {
            initalFormLocks.forEach((lockedBy: string, index: number) => {
                runInAction(() => {
                    this.formLocks[this.registeredForms[index]] = {
                        locked: lockedBy !== "",
                        lockedBy: lockedBy,
                        id: "",
                    };
                });
            });
        });
    }

    public setSignalRAccessToken(token: string) {
        this.signalRAccessToken = token;
    }

    // Track a new set of forms

    public setFormsInfo(ref: string, forms: number[]) {
        // Create callback
        const setForms = () => {
            // Dispose to unregister from any previous notifications
            this.disposers.forEach((disposer) => {
                disposer.dispose();
            });
            // Reset disposers
            this.disposers = [];
            // Reset which forms are locked
            this.formLocks = this.getResetFormLocks;
            // Set the reference for the new forms
            this.formRef = ref;
            // Set new forms as the registered forms
            this.registeredForms = forms;
            // Register for notifications for new forms
            this.registerForms();
            // Get which forms are already locked
            this.setInitialFormLocks();
        };

        // If the connection to the server is ready, run the above callback
        if (this.getIsHubConnected) {
            setForms();
        } else {
            // Otherwise, add it to a queue to invoke when it becomes ready
            this.connectionQueue.push(setForms);
        }
    }

    public registerForms() {
        // Register for notifications for all form types in list
        this.registeredForms.forEach((form) => {
            this.disposers.push(this.registerFormLocks(form));
        });
    }

    public get getIsHubConnected() {
        return this.signalRConcurrencyHubConnection.state === HubConnectionState.Connected;
    }

    public get getResetFormLocks() {
        // The observable dictionary for tracking locked form types
        return observable<{
            [ref: string]: { locked: boolean; lockedBy: string; id: string };
        }>({});
    }

    public onLock = (lockForm: LockForm) => {
        // Handle message from server to lock a form type
        this.formLocks[lockForm.formType] = {
            locked: true,
            lockedBy: lockForm.lockedByName,
            id: lockForm.id,
        };
    };

    public onUnlock = (lockForm: LockForm) => {
        // Handle message from server to unlock a form type
        this.formLocks[lockForm.formType] = {
            ...this.formLocks[lockForm.formType],
            locked: false,
        };
    };

    public get getFormRef() {
        return this.formRef;
    }

    // Try to lock the form if not already, returning a promise which will resolve to a boolean indicating if this client now has the form type locked

    public async lockForm(formType: number) {
        let success = await this.signalRConcurrencyHubConnection.invoke<boolean>(`TryLockFormForEditing`, formType, this.formRef);
        if (success) {
            // Wait for notification from the server to be added to the formLocks dictionary for verification
            //console.assert("Fix");
            //await when(() => this.formLocks[formType] && this.formLocks[formType].locked && this.formLocks[formType].lockedBy === this.domainStores!.AccountStore.UserName);
            // Add it to the list of forms locked by this client
            this.myLockedForms.push(formType);
            return true;
        }
        return false;
    }

    public async forceUnlockForm(formType: number, formRef: string) {
        await this.signalRConcurrencyHubConnection.invoke(`ForceUnlockForm`, formType, formRef);
    }

    public unlockAllForms() {
        this.myLockedForms.forEach((form) => {
            this.unlockForm(form);
        });
    }

    // Unlock a form type

    public unlockForm(formType: number) {
        this.signalRConcurrencyHubConnection.send(`UnlockForm`, this.formLocks[formType].id);
        // Remove from list of forms locked by this client
        this.myLockedForms.splice(this.myLockedForms.indexOf(formType), 1);
    }

    public getLocker(formType: number) {
        let form = this.formLocks[formType];
        return form ? form.lockedBy : "";
    }

    public getIsFormLocked(formType: number): boolean {
        let form = this.formLocks[formType];
        // Don't include if the form is locked by this client
        return form ? form.locked && form.lockedBy !== CoreStoreInstance.DisplayName : false;
    }

    // Register for notifications for a form type
    private registerFormLocks = (formType: number): Disposer => {
        this.signalRConcurrencyHubConnection.send(`JoinFormLockGroup`, formType, this.formRef).catch((error) => {
            console.error(`Failed to "JoinFormLockGroup": ${error}`);
        });

        // Return a disposer to unregister from the notifications
        return {
            dispose: () => {
                if (this.signalRConcurrencyHubConnection !== null && this.signalRConcurrencyHubConnection !== undefined) {
                    this.signalRConcurrencyHubConnection.send(`LeaveFormLockGroup`, formType, this.formRef).catch((error) => {
                        console.error(`Failed to "LeaveFormLockGroup": ${error}`);
                    });
                }
            },
        };
    };

    // Connect to the server

    public connect = () => {
        // Make sure client isn't already connected
        if (this.signalRConcurrencyHubConnection.state === HubConnectionState.Disconnected) {
            this.signalRConcurrencyHubConnection.start().then(() => {
                // Connection established
                // Track the connection id of this connection
                this.cid = this.signalRConcurrencyHubConnection.connectionId!;

                this.signalRConcurrencyHubConnection.invoke<string>(`GetServerSessionId`).then((serverSessionId: string) => {
                    runInAction(() => {
                        this.connectedServer = serverSessionId;
                    });
                });
                // Add handler for if the server goes down and needs to reconnect
                this.signalRConcurrencyHubConnection.onreconnected(() => {
                    // Register for notifications on the forms on new connection
                    this.registerForms();
                    // Send a reconnection message to handle which forms are locked by this client
                    this.signalRConcurrencyHubConnection.send(`Reconnected`, this.cid, this.myLockedForms, this.connectedServer);
                    // Track the connection id of this new connection
                    this.cid = this.signalRConcurrencyHubConnection.connectionId!;
                    // Get which forms are already locked
                    this.setInitialFormLocks();
                });
                this.signalRConcurrencyHubConnection.onreconnecting(() => {
                    let intervalId = window.setInterval(() => {
                        axios.post(`/api/account/GetSignalRToken`).then((apiResult: AxiosResponse<ApiResult<string>>) => {
                            if (apiResult.status === 200 && apiResult.data && apiResult.data.wasSuccessful) {
                                window.clearInterval(intervalId);
                                this.setSignalRAccessToken(apiResult.data.payload);
                            }
                        });
                    }, 1000);
                });
                // Register handlers for lock & unlock notifications
                this.signalRConcurrencyHubConnection.on(`LockForm`, this.onLock);
                this.signalRConcurrencyHubConnection.on(`UnlockForm`, this.onUnlock);
                // Invoke any callbacks in the connection queue
                this.connectionQueue.forEach((cb) => {
                    runInAction(() => {
                        cb();
                    });
                });
            });
        }
    };

    // Disconnect from the server

    public disconnect = () => {
        // Make sure client isn't already disconnected
        if (this.signalRConcurrencyHubConnection.state === HubConnectionState.Connected) {
            // Unregister handlers for lock & unlock notifications
            this.signalRConcurrencyHubConnection.off(`LockForm`, this.onLock);
            this.signalRConcurrencyHubConnection.off(`UnlockForm`, this.onUnlock);
            // Stop the connection
            this.signalRConcurrencyHubConnection.stop();
        }
    };
}
