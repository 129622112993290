import { observable, action, makeAutoObservable, makeObservable, computed } from "mobx";
import { set as _set } from "lodash-es";
import { get as _get } from "lodash-es";
import { IModel } from "./IModel";
import { InputValuesDirty, InputValuesErrors, InputValuesValidity } from "./IModel";
import { getParentObjectPath, isDate } from "../Utils/Utils";
import { FieldType } from "../Models";
import { ViewModelBase } from "../ViewModels";

export abstract class ModelBase<T = any> implements IModel<T> {
    public Errors = {} as InputValuesErrors<T>;
    public Valid = {} as InputValuesValidity<T>;
    public Dirty = {} as InputValuesDirty<T>;
    //@observable public Touched = {} as InputValuesTouched<T>;

    constructor() {
        makeObservable(this, {
            Errors: observable,
            Valid: observable,
            Dirty: observable,
            toModel: action,
            safeSetValue: action,
            setValue: action,
            setError: action,
            setValid: action,
            setDirty: action,
            // getError: action,
            // getDirty: action,
            // getValue: action,
            // getValid: action,
        });

        //Loop through added properties setting their default values
        for (let prop in this) {
            if (prop != "Errors" && prop != "Valid" && prop != "Dirty") {
                // @ts-ignore
                this.Errors[prop] = "";
                // @ts-ignore
                this.Valid[prop] = true;
                // @ts-ignore
                this.Dirty[prop] = false;
                // @ts-ignore
                //this.Touched[prop] = false;
            }
        }
    }

    //https://github.com/typestack/class-transformer#working-with-nested-objects

    toModel(model: any): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (model[key] && (this[key] instanceof Date )) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

/*    toModel= (model: any): void => {
        let self = this;
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if(this[key] instanceof ViewModelBase)
                {
                    (this[key] as ViewModelBase).model.toModel(model[key])
                }
                let a = self[key];
                if(Array.isArray(this[key]))
                {
                    let newvm = Object.create(ViewModelBase);
                    for(let vm of (model[key] as ViewModelBase[]))
                    {
                        debugger;

                        (this[key] as ViewModelBase).model.toModel(model[key])
                    }
                    (this[key] as ViewModelBase[]).push(Object.create(ViewModelBase));
                }
                else if (model[key] && (this[key] instanceof Date || isDate(model[key]))) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }*/

    public safeSetValue(fieldName: any, value: any) {
        if (window.IE11) {
            (this as any)[fieldName] = value;
        } else {
            _set(this, fieldName as any, value);
        }
    }

    public setValue<TR>(fieldName: keyof FieldType<T>, value: TR): void {
        this.safeSetValue(fieldName as any, value);
    }

    public setError(fieldName: keyof FieldType<T>, value: string): void {
        let path = getParentObjectPath(fieldName as any, "Errors");
        this.safeSetValue(path, value);
    }

    public setValid(fieldName: keyof FieldType<T>, value: boolean): void {
        let path = getParentObjectPath(fieldName as any, "Valid");
        this.safeSetValue(path, value);
    }

    public setDirty(fieldName: keyof FieldType<T>, value: boolean): void {
        let path = getParentObjectPath(fieldName as any, "Dirty");
        this.safeSetValue(path, value);
    }

    // public getValue<TR>(fieldName: keyof FieldType<T>): TR {
    //     return _get(this, fieldName as any);
    // }

    // public getValid(fieldName: keyof FieldType<T>): boolean {
    //     let path = getParentObjectPath(fieldName as any, "Valid");
    //     return _get(this, path);
    // }
    // public getError(fieldName: keyof FieldType<T>): string {
    //     let path = getParentObjectPath(fieldName as any, "Errors");
    //     return _get(this, path);
    // }

    // public getDirty(fieldName: keyof FieldType<T>): boolean {
    //     let path = getParentObjectPath(fieldName as any, "Dirty");
    //     return _get(this, path);
    // }

    // @action
    // public setTouched(fieldName: keyof FieldType<T>, value: boolean): void {
    //     let path = getParentObjectPath(fieldName as any, "Touched");
    //     this.safeSetValue(path, value);
    // }

    // public getTouched(fieldName: keyof FieldType<T>): boolean {
    //     let path = getParentObjectPath(fieldName as any, "Touched");
    //     return _get(this, path);
    // }
}
