import { AccountStatus, ApiResult, ApiResultError, ApiResultErrorType, ModelBase } from "../../Models";
import axios, * as Axios from "axios";
import { action, makeObservable, observable } from "mobx";
import { CheckHttpStatus, ConsoleLogger, deleteJWT, getJWT, isJWTValid, MergeDefaultConfig, setJWT } from "../../Utils";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { container } from "tsyringe";

type ProtectedActions = "setIsLoading" | "setIsErrored" | "setErrors";
export class HttpClient {
    //private model: T | null = null;
    public client: AxiosInstance = {} as AxiosInstance;
    private logger = container.resolve(ConsoleLogger);

    public onError: any = null;
    public onResponse: any = null;
    public IsLoading = false;
    public IsErrored = false;
    public Errors = "";
    public Valid = false;

    protected setIsLoading = (state: boolean) => (this.IsLoading = state);
    protected setIsErrored = (state: boolean) => (this.IsErrored = state);
    protected setErrors = (state: string) => (this.Errors = state);

    constructor() {
        this.logger.logDebug("Httpclient ctor called");
        const timeout = (window as any).IsDev ? 240000 : 30000;
        this.client = axios.create({ timeout: timeout, params: {} });
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        this.client.interceptors.response.use(this._handleResponse, this._handleError);

        makeObservable<HttpClient, ProtectedActions>(this, {
            IsLoading: observable,
            IsErrored: observable,
            Errors: observable,
            Valid: observable,
            setIsLoading: action,
            setIsErrored: action,
            setErrors: action,
        });
    }

    public set setOnErrorCallback(errorCallback: any) {
        this.onError = errorCallback;
        (window as any).errorCallback5611 = errorCallback;
    }

    public set setOnResponseCallback(responseCallback: any) {
        this.onResponse = responseCallback;
        (window as any).responseCallback5611 = responseCallback;
    }

    public set setClient(client: AxiosInstance) {
        this.client = client;
    }

    public get getClient() {
        return this.client;
    }

    private _handleResponse = (response: AxiosResponse) => {
        let responseCallback = (window as any).responseCallback5611;
        if (responseCallback) {
            responseCallback(response);
        }
        return response;
    };

    protected _handleError = (error: any) => {
        let errorCallback = (window as any).errorCallback5611;
        if (errorCallback) {
            errorCallback(error);
        } else {
            return Promise.reject(error);
        }
        /*debugger;
        let res = error.response;
        if (res && res.status == 401) {
        } else if (res && res.status == 404) {
            console.log("We found an error!!!");
        }
        console.error("Looks like there was a problem. Status Code: " + res!.status);
        return Promise.reject(error);*/
    };

    public registerInterceptor(callbackError: any) {
        this.client.interceptors.response.use(this._handleResponse, callbackError);
    }

    Get = async <TPayload = ApiResult<any>>(url: string, useBearer: boolean = true, config?: Axios.AxiosRequestConfig): Promise<ApiResult<TPayload>> => {
        this.setIsLoading(true);
        let thisconfig = await this.getConfig(useBearer, config);
        let response: any = null;
        try {
            console.log("Calling", url);
            response = await this.client.get<AxiosResponse>(url, thisconfig);
            this.setResponseMeta(response);
        } catch (error) {
            return this.processError(error) as any as ApiResult<TPayload>;
        } finally {
            this.setIsLoading(false);
        }

        return response.data;
    };

    Post = async <TPayload = ApiResult<any>>(url: string, model?: any, useBearer: boolean = true, config?: Axios.AxiosRequestConfig): Promise<ApiResult<TPayload>> => {
        this.setIsLoading(true);
        let response: any;
        try {
            let payload = model;
            if (model instanceof ModelBase) {
                payload = this.getAnyModelAsPayload(model);
            }
            let thisconfig = await this.getConfig(useBearer, config);
            console.log("Calling", url);
            response = await this.client.post<AxiosResponse>(url, payload, thisconfig);
            this.setResponseMeta(response);
        } catch (error) {
            return this.processError(error) as any as ApiResult<TPayload>;
        } finally {
            this.setIsLoading(false);
        }

        return response.data;
    };

    Put = async <TPayload = ApiResult<any>>(url: string, model?: any, useBearer: boolean = true, config?: Axios.AxiosRequestConfig): Promise<ApiResult<TPayload>> => {
        this.setIsLoading(true);
        let response: AxiosResponse;
        try {
            let payload = model;
            if (model instanceof ModelBase) {
                payload = this.getAnyModelAsPayload(model);
            }
            let thisconfig = await this.getConfig(useBearer, config);
            response = await this.client.put<AxiosResponse>(url, payload, thisconfig);
            this.setResponseMeta(response);
        } catch (error) {
            return this.processError(error) as any as ApiResult<TPayload>;
        } finally {
            this.setIsLoading(false);
        }

        return response.data;
    };

    Delete = async <TPayload = ApiResult<any>>(url: string, model?: any, useBearer: boolean = true, config?: Axios.AxiosRequestConfig): Promise<ApiResult<TPayload>> => {
        this.setIsLoading(true);
        let response: AxiosResponse;
        try {
            let payload = model;
            if (model instanceof ModelBase) {
                payload = this.getAnyModelAsPayload(model);
            }
            let thisconfig = await this.getConfig(useBearer, config);
            thisconfig.data = payload;
            response = await this.client.delete<AxiosResponse>(url, thisconfig);
            this.setResponseMeta(response);
        } catch (error) {
            return this.processError(error) as any as ApiResult<TPayload>;
        } finally {
            this.setIsLoading(false);
        }

        return response.data;
    };

    private setResponseMeta(response: AxiosResponse) {
        if (response && response.status && response.data) {
            (response.data as ApiResult).status = response.status;
            (response.data as ApiResult).statusText = response.statusText;
            (response.data as ApiResult).headers = response.headers;
        }
    }

    public getModelAsPayload(model: any) {
        let payload = this.getAnyModelAsPayload(model);
        return payload;
    }

    private getAnyModelAsPayload(model: any) {
        let exclude = ["Dirty", "Errors", "Valid", "localComputedValues", "localValues", "isPropertyDirty"];
        let payload = {} as any;
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (!exclude.includes(key)) {
                    //EN: Check for recursed models
                    if (key == "model" && typeof model[key] === "object") {
                        continue;
                    }
                    (payload as any)[key] = (model as any)[key];
                    if (typeof (payload as any)[key] === "string") {
                        //EN: Exclude null characters in a string
                        ((payload as any)[key] as any as string).replace(/\0/g, "");
                    }
                }
            }
        }
        return payload;
    }

    processHttpError = <TPayload>(error: any): ApiResult => {
        this.setIsErrored(true);
        this.setIsLoading(false);
        this.setErrors(error);
        let errors: ApiResultError[] = [];
        if (error) {
            if (error.status === 400) {
                console.log("Bad Rquest");
                errors.push({
                    message: "You made a bad request to this resource",
                    type: ApiResultErrorType.Basic,
                });
            } else if (error.status === 401) {
                console.log("Unauthorised");
                errors.push({
                    message: "You are unauthorised to access this resource",
                    type: ApiResultErrorType.Authorisation,
                });
            } else if (error.status === 404) {
                console.log("Endpoint not found");
                errors.push({
                    message: "Api endpoint not found",
                    type: ApiResultErrorType.Basic,
                });
            }
        }
        let apiResult: ApiResult = {
            wasSuccessful: false,
            errors: errors,
            status: error.status ?? "",
            statusText: error.statusText ?? "",
            headers: error.headers,
            payload: error.data,
        };

        console.error("Error response from api call", apiResult);
        return apiResult;
    };

    processError = <TPayload>(error: any) => {
        this.setIsErrored(true);
        this.setIsLoading(false);
        this.setErrors(error);
        let errors: ApiResultError[] = [];
        let apiResult: ApiResult = {
            wasSuccessful: false,
            errors: errors,
            status: 500,
            statusText: error,
            headers: {},
            payload: {},
        };

        return Promise.resolve(apiResult);
    };

    getConfig = async (useBearer: boolean, config?: Axios.AxiosRequestConfig) => {
        const requestConfig = MergeDefaultConfig(config);
        //Sets the bearer on every header if available
        //Note: You might need to remove this bearer if calling 3rd party api's
        let jwt = await getJWT();

        if (!(await isJWTValid())) {
            await deleteJWT();
        }

        if (jwt && jwt.length > 0 && useBearer) {
            requestConfig.headers = {
                Authorization: "Bearer " + jwt,
                "content-type": "application/json",
                xsrfCookieName: "XSRF-TOKEN",
                xsrfHeaderName: "X-XSRF-TOKEN",
            };
        } else {
            requestConfig.headers = {
                "content-type": "application/json",
                xsrfCookieName: "XSRF-TOKEN",
                xsrfHeaderName: "X-XSRF-TOKEN",
            };
        }
        return requestConfig;
    };
}

class ResponseModel extends ModelBase {
    fromDto(model: any): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }
    toDto(model: any): void {
        throw new Error("Method not implemented.");
    }
}
