import { makeObservable } from "mobx";
/* eslint-disable @typescript-eslint/no-parameter-properties */
import { action, observable, runInAction } from "mobx";
import * as GoogleAnalytics from "../Utils/App/GoogleAnalyticsUtils";
import { FormLockConcurrencyHubStore } from "./FormLockConcurrencyHubStore";
import * as MobX from "mobx";
import { EventArgs } from "../Utils";
import { singleton } from "tsyringe";

export type Action = "PUSH" | "POP" | "REPLACE";

export interface IHistory {
    action: Action;
    push(url: string): void;
    replace(url: string): void;
    go(n: number): void;
    goBack(): void;
    goForward(): void;
}
export class CoreStore {
    public IsLoggedIn = false;
    public DisplayName = "";
    public screenWidth = 0;
    public isMobile = false;
    public isTablet = false;
    public isDesktop = false;
    public FormLockConcurrencyHubStore = new FormLockConcurrencyHubStore();
    public GlobalHistory: IHistory = {} as IHistory;

    public constructor() {
        CoreStoreInstance = this;
        makeObservable(this, {
            IsLoggedIn: observable,
            DisplayName: observable,
            screenWidth: observable,
            isMobile: observable,
            isTablet: observable,
            isDesktop: observable,
            SetLoggedIn: action,
            SetDisplayName: action,
        });
    }

    public SetLoggedIn(isLoggedIn: boolean) {
        this.IsLoggedIn = isLoggedIn;
    }

    public SetDisplayName(displayName: string) {
        this.DisplayName = displayName;
    }

    public SetHistory(history: any) {
        this.GlobalHistory = history;
    }

    public Init = (signalRAccessToken: string, useFormLockConcurrency: boolean) => {
        //console.assert(this.theme.palette, "You must set the theme before initiating. Use SetTheme");
        //return;

        window.addEventListener("resize", () => {
            this.setWidth();
        });
        this.setWidth();
        this.FormLockConcurrencyHubStore.init(signalRAccessToken, useFormLockConcurrency);

        // EN: For debugging
        //window.MobX = MobX;
    };

    private setWidth = () => {
        runInAction(() => {
            this.screenWidth = document.body.clientWidth;
            this.isMobile = this.screenWidth <= 960;
            this.isTablet = this.screenWidth > 960 && this.screenWidth <= 1280;
            this.isDesktop = this.screenWidth > 1280;

            if (this.isMobile) {
                document.body.className = "mobile";
            }
            if (this.isTablet) {
                document.body.className = "tablet";
            }
            if (this.isDesktop) {
                document.body.className = "desktop";
            }
        });
    };

    public PageView = (path: string, title: string = "") => {
        GoogleAnalytics.pageview(path, "");
    };

    public SendEvent = (args: EventArgs) => {
        GoogleAnalytics.sendEvent(args);
    };
}

export let CoreStoreInstance = {} as CoreStore;
