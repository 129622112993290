/** Indicates the severity of a log message.
 *
 * Log Levels are ordered in increasing severity. So `Debug` is more severe than `Trace`, etc.
 */
import { singleton } from "tsyringe";

export enum LogLevel {
    /** Log level for very low severity diagnostic messages. */
    Trace = 0,
    /** Log level for low severity diagnostic messages. */
    Debug = 1,
    /** Log level for informational diagnostic messages. */
    Information = 2,
    /** Log level for diagnostic messages that indicate a non-fatal problem. */
    Warning = 3,
    /** Log level for diagnostic messages that indicate a failure in the current operation. */
    Error = 4,
    /** Log level for diagnostic messages that indicate a failure that will terminate the entire application. */
    Critical = 5,
    /** The highest possible log level. Used when configuring logging to indicate that no log messages should be emitted. */
    None = 6,
}

/** An abstraction that provides a sink for diagnostic messages. */
export interface ILogger {
    /** Called by the framework to emit a diagnostic message.
     *
     * @param {string} message The message.
     */
    //log(logLevel: LogLevel, message: string): void;
    logCritical(message: string): void;
    logError(message: string): void;
    logWarning(message: string): void;
    logInformation(message: string): void;
    logDebug(message: string): void;
}

export function createLogger(logger?: ILogger | LogLevel) {
    if (logger === undefined) {
        return new ConsoleLogger();
    }

    if (logger === null) {
        return NullLogger.instance;
    }

    if ((logger as ILogger).logDebug) {
        return logger as ILogger;
    }

    return new ConsoleLogger();
}

/** A logger that does nothing when log messages are sent to it. */
export class NullLogger implements ILogger {
    /** The singleton instance of the {@link @microsoft/signalr.NullLogger}. */
    public static instance: ILogger = new NullLogger();

    private constructor() {}

    /** @inheritDoc */
    // tslint:disable-next-line
    //public log(_logLevel: LogLevel, _message: string): void {}
    logCritical(message: string): void {}
    logError(message: string): void {}
    logWarning(message: string): void {}
    logInformation(message: string): void {}
    logDebug(message: string): void {}
}

@singleton()
export class ConsoleLogger implements ILogger {
    // Public for testing purposes.
    public outputConsole: {
        error(message: any): void;
        warn(message: any): void;
        info(message: any): void;
        log(message: any): void;
    };

    // constructor(minimumLogLevel: LogLevel) {
    //     this.minimumLogLevel = minimumLogLevel;
    //     this.outputConsole = console;
    // }
    constructor() {
        this.outputConsole = console;
    }

    public logCritical(message: string): void {
        if ((window as any).logLevel <= LogLevel.Critical) {
            this.outputConsole.error(`[${new Date().toISOString()}] Critical: ${message}`);
        }
    }
    public logError(message: string): void {
        if ((window as any).logLevel <= LogLevel.Error) {
            this.outputConsole.error(`[${new Date().toISOString()}] Error: ${message}`);
        }
    }
    public logWarning(message: string): void {
        if ((window as any).logLevel <= LogLevel.Warning) {
            this.outputConsole.warn(`[${new Date().toISOString()}] Warning: ${message}`);
        }
    }

    public logInformation(message: string): void {
        if ((window as any).logLevel <= LogLevel.Information) {
            this.outputConsole.info(`[${new Date().toISOString()}] Information: ${message}`);
        }
    }

    public logDebug(message: string): void {
        if ((window as any).logLevel <= LogLevel.Debug) {
            this.outputConsole.log(`[${new Date().toISOString()}] Debug: ${message}`);
        }
    }
}
