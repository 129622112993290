import { HttpError } from "./HttpError";

//AJ: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/401
export class HttpUnauthorizedError extends HttpError {
    constructor() {
        //AJ: https://stackoverflow.com/questions/47941324/inherited-exceptions-and-instanceof
        const targetPrototype = new.target.prototype;
        super(401, "Unauthorized");
        Object.setPrototypeOf(this, targetPrototype);
    }
}
